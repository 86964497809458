import React from "react";
import Helmet from "react-helmet";
import { PageLocale, URL } from "../../../templates/Page/types";

interface Props {
  locale: PageLocale;
  title: string;
  titleSuffix: string;
  description: string;
  favicon: URL;
  image?: URL;
}

function SEO(props: Props) {
  const meta = [];
  if (props.image) {
    meta.push({
      property: `og:image`,
      content: props.image,
    });
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: props.locale,
      }}
      title={props.title}
      titleTemplate={`%s${props.titleSuffix}`}
      meta={[
        {
          name: `description`,
          content: props.description,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: props.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: props.description,
        },
      ].concat(meta)}
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: props.favicon,
        },
      ]}
    />
  );
}

export default SEO;
